/**
 * Variables
 * In this file, you can add all the variables you need
 */
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
// Colors
//...............
$color-fuxia: #ff0005;
$color-purple: #230f3e;
$color-dark-grey: #333;
$color-black: #000;
$color-white: #fff;
$color-light-grey: #bbb;
//
//...............
// $primary-color: $color-red;
// $second-color: $color-dark-grey;
// $third-color: $color-black;
// $fourth-color: $color-light-grey;
// $body-bg: #fff;
// $text-color: $second-color;
// Fonts
//...............
$primary-font: "lato",
	helvetica,
	arial,
	sans-serif;
$title-font: "nexa", helvetica, arial, sans-serif;

$secondary-font: "Arvo",
	georgia,
	times,
	serif;
$font-size: 16px;
//fonts-type
//...............
$font_thin:100;
$font_light: 300;
$font_regular:400;
$font_smbold:600;
$font_bold:700;
$font_exbold:900;
