.wp-caption {
	margin-bottom: 1.5em;
	max-width: 100%;

	img[class*="wp-image-"] {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.wp-caption-text {
		margin: 0.8075em 0;
	}
}

.wp-caption-text {
	text-align: center;
}
