/* Global
--------------------------------------------- */
.btn {
	font-size: 16px;
	padding: 8px 30px;
	border-radius: 50px;

	&__fuxia {
		background-color: $color-fuxia;
		color: $color-white;
		transition: all 0.2s linear;

		&:hover {
			background-color: $color-black;
			color: $color-white;
			transition: all 0.2s linear;
		}
	}
}

.wp-block-button.is-style-fill .wp-block-button__link {
	background-color: $color-fuxia;
	color: $color-white;
	font-size: 16px;
	padding: 8px 30px;
	border-radius: 50px;
	transition: all 0.2s linear;

	&:hover {
		background-color: $color-black;
		color: $color-white;
		transition: all 0.2s linear;
	}
}

a {

	&.arrow {
		color: $color-fuxia;
		font-size: 18px;
		position: relative;
		font-weight: 800;
		transition: all 0.2s linear;

		&::after {
			content: "";
			background-image: url(../img/arrow-fuxia.svg);
			background-size: 20px;
			height: 24px;
			width: 20px;
			background-repeat: no-repeat;
			position: absolute;
			background-position-y: 5px;
			margin-left: 10px;
			transition: all 0.2s linear;
		}

		&:hover {
			color: $color-black;
			text-decoration: none;
			transition: all 0.2s linear;

			&::after {
				background-image: url(../img/arrow-forward.svg);
				margin-left: 15px;
				transition: all 0.2s linear;
			}
		}
	}
}
