/*--------------------------------------------------------------
# Custom CSS needed in sass or css format.
--------------------------------------------------------------*/
//It should be modified as few as possible.
// In this file you can edit styles which don’t have
// their own scss file. You can also add pure CSS
body {
	font-family: $primary-font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 18px;
	font-weight: 400;
	line-height: 30px;
}

p {

	a {
		color: $color-fuxia;
	}
}

h1 {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-weight: 800;
	font-size: 120px;
	line-height: 150px;
}

h2 {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-weight: 700;
	font-size: 65px;

	span,
	strong {
		color: $color-fuxia;
	}
}

.container {
	max-width: 1285px;
}

.navbar {

	&.navbar-expand-md {

		&.navbar-light {
			height: 100px;
		}
	}

	.btn.btn__fuxia {
		font-weight: 900;
	}
}

.navbar-brand {
	position: absolute;
	left: 47%;
}

.advantage {
	background-image: url(../../dist/img/pixels.svg);
	min-height: 750px;
	background-repeat: no-repeat;
	background-position: right;
}

.aliance {

	.logo {
		width: 25%;
		white-space: nowrap;
		text-align: center;
		margin: 1em 0;
		float: left;
		height: 100px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;

		img {
			margin: auto;
		}
	}
}

.alignwide {
	max-width: 1285px;
	margin: auto;
	padding: 0 15px;
}

ul {

	li {

		line-height: 30px;
		margin-right: 20px;
	}
}

.news-latter {

	form {

		input {
			border: none;
			background: #f5f5f5;
			padding: 15px;
			border-radius: 100px;

			&.wpcf7-submit {
				background: $color-fuxia;
				margin-left: -50px;
				position: absolute;
				color: $color-white;
				z-index: 1;
				transition: all 0.2s linear;

				&:hover {
					background: $color-black;
					transition: all 0.2s linear;
				}
			}
		}
	}
}
