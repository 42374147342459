/* Blocks CSS
--------------------------------------------- */
.menu {
	margin-bottom: 10%;
}

.header-staa {

	h1 {
		text-align: center;

		span,
		strong {
			display: block;
			position: relative;

			&::before {
				content: "";
				background-image: url(../img/underline.svg);
				background-size: 93%;
				background-repeat: no-repeat;
				height: 170px;
				width: 500px;
				position: absolute;
				background-position-x: center;
				background-position-y: 135px;
			}
		}
	}
}

h1 {
	text-align: center;

	span,
	strong {
		display: block;
		position: relative;

		&::before {
			content: "";
			background-image: url(../img/underline.svg);
			background-size: 93%;
			background-repeat: no-repeat;
			height: 170px;
			width: 500px;
			position: absolute;
			background-position-x: center;
			background-position-y: 135px;
			animation-name: fadeInUp;
		}
	}
}

.text-img {
	padding: 5% 0;

	.img-staa {
		text-align: right;
	}
}

.advantage {

	.text {
		font-weight: 400;
		font-size: 33px;
		line-height: 50px;
		padding-left: 17%;
	}

	.numbers {
		margin-top: 160px;

		span {
			font-weight: 400;
			font-size: 90px;
			line-height: 100px;
			font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
			color: $color-fuxia;
		}

		p {
			font-size: 22px;
		}
	}
}

.aliance {
	padding: 5% 0;
}

.testimonials {
	padding: 5% 0;

	.container {
		background: #f8f8f9;
		padding: 2% 0 5%;
	}

	.carousel-item,
	.carousel-item.active {
		text-align: center;
		padding: 0 150px;

		blockquote {
			font-weight: 400;
			font-size: 28px;
			line-height: 50px;
			position: relative;
			padding-top: 80px;

			&::before {
				content: "";
				background-image: url(../img/quote.svg);
				background-size: contain;
				background-repeat: no-repeat;
				height: 50px;
				width: 100%;
				position: absolute;
				background-position-x: center;
				left: 0;
				top: 11px;
			}
		}

		cite {
			font-family: lato;
			font-weight: 600;
			font-size: 20px;
			line-height: 30px;
			font-style: normal;
		}
	}

	.carousel-indicators {
		position: absolute;
		right: 0;
		bottom: -50px;
		left: 0;
		z-index: 15;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		padding-left: 0;
		margin-right: 15%;
		margin-left: 15%;
		list-style: none;

		li {
			width: 10px;
			height: 10px;
			margin-right: 3px;
			margin-left: 3px;
			text-indent: -999px;
			cursor: pointer;
			background-color: #fff;
			background-clip: padding-box;
			border-top: 0;
			border-bottom: 0;
			transition: opacity 0.6s ease;
			border-radius: 100px;
			border: 2px solid $color-fuxia;
			opacity: 1;

			&.active {
				background-color: $color-fuxia;
			}
		}
	}
}

footer {
	background: $color-purple;
	padding: 5% 0;

	.footer {

		&__touch {
			color: $color-white;
			margin-bottom: 50px;

			p {
				font-size: 18px;
			}

			legend {
				font-size: 60px;
				line-height: 60px;
				margin-bottom: 40px;
				font-weight: 600;

				span {
					color: $color-fuxia;
				}
			}

			.btn.btn__fuxia {
				font-weight: 900;
			}
		}

		&__menu {

			ul {
				display: inline;
				list-style: none;
				padding: 0;

				li {
					display: inline-block;
					margin-right: 30px;

					a {
						color: $color-white;
						font-size: 24px;
						font-family: $primary-font;
						font-weight: 700;

					}
				}
			}
		}

		&__cop {
			color: $color-white;
		}
	}
}

.text-intro {
	max-width: 1000px;
	margin: auto;

	p {
		line-height: 40px;
	}
}
