/*!
Theme Name: base-theme
Author: transfuture
Author URI: https://transfuture.com/

Description: Base theme to start all projects

Version: 1.0.0
Last update: 06-02-2020
Tested up to:
minimum PHP version required: 7.1
Text Domain: base-theme

Normalizing styles, version: 8.0.1 https://necolas.github.io/normalize.css/
*/


/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Generic
	- Normalize
	- Box sizing
## Layouts
# Components
	- Navigation
	- Posts and pages
	- Comments
	- Widgets
	- Media
	- Captions
	- Galleries
	- Global
# plugins

# Utilities
	- Accessibility
	- Alignments
	- Variable
# Blocks
	-
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Generic
--------------------------------------------------------------*/


/*--------------------------------------------------------------
# Utilities
--------------------------------------------------------------*/

@import "utilities/variable";

/* Normalize
--------------------------------------------- */

@import "generic/normalize";

/* Box sizing
--------------------------------------------- */

@import "generic/box-sizing";

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/


/* @import "layouts/content-sidebar";
@import "layouts/sidebar-content"; */


/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/

@import "components/components";

/*--------------------------------------------------------------
# Plugins
--------------------------------------------------------------*/


/* Accessibility
--------------------------------------------- */

@import "utilities/accessibility";

/* Alignments
--------------------------------------------- */

@import "utilities/alignments";

/*--------------------------------------------------------------
# Blocks
--------------------------------------------------------------*/

@import "blocks/block-main";

/*--------------------------------------------------------------
# Custom css
--------------------------------------------------------------*/

@import "custom";
