.main-navigation {
	display: block;
	width: 100%;

	ul {
		display: none;
		list-style: none;
		margin: 0;
		padding-left: 0;

		ul {
			box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
			float: left;
			position: absolute;
			top: 100%;
			left: -999em;
			z-index: 99999;

			ul {
				left: -999em;
				top: 0;
			}

			li {
				line-height: 30px;

				&:hover > ul,
				&.focus > ul {
					display: block;
					left: auto;
				}
			}

			a {
				width: 200px;
			}

			:hover > a,
			.focus > a {
			}

			a:hover,
			a.focus {
			}
		}

		li:hover > ul,
		li.focus > ul {
			left: auto;
		}
	}

	li {
		position: relative;

		&:hover > a,
		&.focus > a {
		}
	}

	a {
		display: block;
		text-decoration: none;
	}

	.current_page_item > a,
	.current-menu-item > a,
	.current_page_ancestor > a,
	.current-menu-ancestor > a {
	}
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
	display: block;
}

@media screen and (min-width: 37.5em) {

	.menu-toggle {
		display: none;
	}

	.main-navigation ul {
		display: flex;
	}
}

.comment-navigation,
.posts-navigation,
.post-navigation {

	.site-main & {
		margin: 0 0 1.5em;
	}

	.nav-links {
		display: flex;
	}

	.nav-previous {
		flex: 1 0 50%;
	}

	.nav-next {
		text-align: end;
		flex: 1 0 50%;
	}
}

.menu {
	max-width: 1285px;
	margin: auto;
	padding: 0 15px;

	.navbar-nav {

		.nav-link {
			font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
			font-weight: 700;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 18px;
			color: $color-black;
			transition: all 0.2s linear;

			&:hover,
			&.active {
				color: $color-fuxia;
			}
		}

		li:hover {

			.dropdown-menu {
				display: block;
				border: none;
				padding: 0;
				width: auto;
				margin-top: -6px;

				li {

					a {
						padding: 0 0 0 7px;
						font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
						font-weight: 700;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
						font-size: 18px;
						color: $color-black;
						transition: all 0.2s linear;

						&:hover,
						&.active {
							background-color: transparent;
							color: $color-fuxia;
						}
					}
				}
			}
		}
	}
}

.dropdown-toggle::after {
	display: none;
}

/* ANIMATION MENU */


.animate {
	animation-duration: 0.3s;
	-webkit-animation-duration: 0.3s;
	animation-fill-mode: both;
	-webkit-animation-fill-mode: both;
}

@keyframes slideIn {

	0% {
		transform: translateY(1rem);
		opacity: 0;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}

	0% {
		transform: translateY(1rem);
		opacity: 0;
	}
}

@-webkit-keyframes slideIn {

	0% {
		-webkit-transform: transform;
		-webkit-opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		-webkit-opacity: 1;
	}

	0% {
		-webkit-transform: translateY(1rem);
		-webkit-opacity: 0;
	}
}

.slideIn {
	-webkit-animation-name: slidein;
	animation-name: slideIn;
}
