/* Global
--------------------------------------------- */
@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-Regular"), url(../../../dist/fonts/Nexa-Trial-Regular.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Bold", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-Bold"), url(../../../dist/fonts/Nexa-Trial-Bold.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-ExtraLightItalic"), url(../../../dist/fonts/Nexa-Trial-ExtraLightItalic.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Black", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 900;
	src: local("Nexa-Trial-Black"), url(../../../dist/fonts/Nexa-Trial-Black.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-ExtraLight"), url(../../../dist/fonts/Nexa-Trial-ExtraLight.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-BlackItalic"), url(../../../dist/fonts/Nexa-Trial-BlackItalic.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-HeavyItalic"), url(../../../dist/fonts/Nexa-Trial-HeavyItalic.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-Book"), url(../../../dist/fonts/Nexa-Trial-Book.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-Thin"), url(../../../dist/fonts/Nexa-Trial-Thin.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-ThinItalic"), url(../../../dist/fonts/Nexa-Trial-ThinItalic.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-ExtraBold"), url(../../../dist/fonts/Nexa-Trial-ExtraBold.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-RegularItalic"), url(../../../dist/fonts/Nexa-Trial-RegularItalic.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-ExtraBoldItalic"), url(../../../dist/fonts/Nexa-Trial-ExtraBoldItalic.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-Heavy"), url(../../../dist/fonts/Nexa-Trial-Heavy.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-BookItalic"), url(../../../dist/fonts/Nexa-Trial-BookItalic.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-BoldItalic"), url(../../../dist/fonts/Nexa-Trial-BoldItalic.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-Light"), url(../../../dist/fonts/Nexa-Trial-Light.woff) format("woff");
}

@font-face {
	font-family: "Nexa Trial Regular", Helvetica, Arial, sans-serif;
	font-style: normal;
	src: local("Nexa-Trial-LightItalic"), url(../../../dist/fonts/Nexa-Trial-LightItalic.woff) format("woff");
}
